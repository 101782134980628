import axios from 'axios';

const baseURLs = {
  development: 'https://shine.dev.api.ideauxbill.in/', // API server url (Development)
  production: 'https://sunnshine.api.ideauxbill.in/', // API server url (Production)
  local: 'http://192.168.29.50:8000/',  // API server url (Aishwarya)
};


// const environment = process.env.NODE_ENV || 'development'; 
const environment ='production'

console.log('environment:', environment);
const request = axios.create({
  baseURL: baseURLs[environment],    

  headers: {
    'X-Requested-With': 'XMLHttpRequest', 
  },
});

export default request;

export const IMG_BASE_URL = baseURLs[environment];   